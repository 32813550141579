export const AppRouts = {
  SIDEBAR: "/",
  DASHBOARD: "/",
  CATEGORY: "/Category",
  SUBCATEGORY: "/Subcategory",
  ADDCATEGORY_WITHOUT_ID: "/AddCategory",
  ADDSUBCATEGORY_WITHOUT_ID: "/AddSubcategory",
  ADDCOURSE_WITHOUT_ID: "/AddCourse",
  ADDWORKSHOP_WITHOUT_ID: "/AddWorkshop",
  ADDCATEGORY: "/Category/:id",
  ADDCOURSE: "/Course/:id",
  ADDSUBCATEGORY: "/Subcategory/:id",
  ADDWORKSHOP: "/Workshop/:id",
  COURSE: "/Course",
  WORKSHOP: "/Workshop",
  BANNER: "/Banner",
  COURSEDETAILS: "/CourseDetails",
  ADDCOURSEDETAILS_WITHOUT_ID: "/AddCourseDetails",
  ADDCOURSEDETAILS: "/AddCourseDetails",
  WORKSHOPDETAILS: "/WorkshopDetails",
  ADDWORKSHOPDETAILS: "/AddWorkshopDetails",
  ADDWORKSHOPDETAILS_WITHOUT_ID: "/AddWorkshopDetails",
  WEBSITEREVIEW: "/WebsiteReview",
  ADDWEBSITEREVIEW_WITHOUT_ID: "/AddWebsiteReview",
  ADDWEBSITEREVIEW: "/WebsiteReview/:id",
  ENROLL: "/EnrollCourses",
  EnrollWorkshop: "/EnrollWorkshop",
  BLOG: "/Blog",
  ADDBLOG_WITHOUT_ID: "/AddBlog",
  ADDBLOG: "Blog/:id",
  PAYMENT: "/Payment",
  TRAINING: "/CertificationTraining",
  ADDTRAINING: "/CertificationTraining/:id",
  ADDTRAINING_WITHOUT_ID: "/AddCertificationTraining",
  PLACEMENT: "/PlacementCompanies",
  ADDPLACEMENT: "/PlacementCompanies/:id",
  ADDPLACEMENT_WITHOUT_ID: "/AddPlacementCompanies",
  TESTIMONIALS: "/Testimonials",
  ADDTESTIMONIALS_WITHOUT_ID: "/AddTestimonials",
  ADDTESTIMONIALS: "Testimonials/:id",
  UPCOMINGCOURSE: "/UpcomingCourse",
  ADDUPCOMINGCOURSE_WITHOUT_ID: "/AddUpcomingCourse",
  ADDUPCOMINGCOURSE: "UpcomingCourse/:id",
  UPCOMINGCOURSEDETAILS: "/UpcomingCourseDetails",
  ADDUPCOMINGCOURSEDETAILS_WITHOUT_ID: "/AddUpcomingCourseDetails",
  ADDUPCOMINGCOURSEDETAILS: "/AddUpcomingCourseDetails",
};
export const TableName = {
  CATEGORY: "category",
  COURSE: "course",
  SUBCATEGORY: "subcategory",
  BANNER: "banner",
  WORKSHOP: "workshop",
  COURSEDETAILS: "courseDetails",
  WORKSHOPDETAILS: "workshopDetails",
  WEBSITEREVIEW: "websiteReview",
  ENROLL: "enrollCourses",
  ENROLLWORKSHOP: "enrollWorkshop",
  BLOG: "blog",
  PAYMENT: "payment",
  CERTIFICATION: "certification",
  COMPANIES: "companies",
  TESTIMONIALS: "testimonials",
  UPCOMINGCOURSE: "upcomingCourse",
  UPCOMINGCOURSEDETAILS: "upcomingCourseDetails",
};
export const StorageBucket = {
  COURSE: "course",
  CATEGORY: "category",
  SUBCATEGORY: "subcategory",
  COURSEICON: "courseicon",
  BANNER: "banner",
  WORKSHOP: "workshop",
  COURSEDETAILS: "courseDetails",
  WORKSHOPDETAILS: "workshopDetails",
  WEBSITEREVIEW: "websiteReview",
  BLOG: "blog",
  CERTIFICATION: "certification",
  COMPANIES: "companies",
  UPCOMINGCOURSE: "upcomingCourse",
};
