import React, { useEffect, useState } from "react";
import SubHeader from "./SubHeader";
import { Link, useParams } from "react-router-dom";
import { AppRouts, StorageBucket, TableName } from "./Constant";
import useSupabase from "./utils/useSupabase";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  InputLabel,
  Typography,
  TextField,
  Stack,
  Button,
} from "@mui/material";
import ImageUpload from "./ImageUpload";
import { useFormik } from "formik";

import * as yup from "yup";
import CustomTextField from "./CustomTextField";
import { removeImage, uploadImage } from "./Helperfunction";
import { showToast } from "./toastUtils";
const AddUpcomingCourseDetails = () => {
  const supabase = useSupabase();
  const navigate = useNavigate();
  const params = useParams();
  const { id } = useParams();
  const { detailId } = useParams();
  const [imagesPreview, setImagesPreview] = useState([]);
  const [originalImage, setOriginalImage] = useState();
  const [isNewImageUp, setIsNewImageUp] = useState(false);
  const initialValues = {
    courseName: "",
    courseDesc: "",
    topic1: "",
    topic2: "",
    topic3: "",
    topic4: "",
    topic5: "",
    desc1: "",
    desc2: "",
    desc3: "",
    desc4: "",
    desc5: "",
    faq1: "",
    faq2: "",
    faq3: "",
    faq4: "",
    faq5: "",
    faq6: "",
    answer1: "",
    answer2: "",
    answer3: "",
    answer4: "",
    answer5: "",
    answer6: "",
  };
  const validationSchema = yup.object({
    courseName: yup.string().required("Course Name is required"),
    courseDesc: yup.string().required("Course Desc is required"),
    topic1: yup.string().required("Topic Name is required"),
    topic2: yup.string().required("Topic Name is required"),
    topic3: yup.string().required("Topic Name is required"),
    topic4: yup.string().required("Topic Name is required"),
    topic5: yup.string().required("Topic Name is required"),
    desc1: yup.string().required("Topic Desc is required"),
    desc2: yup.string().required("Topic Desc is required"),
    desc3: yup.string().required("Topic Desc is required"),
    desc4: yup.string().required("Topic Desc is required"),
    desc5: yup.string().required("Topic Desc is required"),
    faq1: yup.string().required("FA Question is required"),
    faq2: yup.string().required("FA Question is required"),
    faq3: yup.string().required("FA Question is required"),
    faq4: yup.string().required("FA Question is required"),
    faq5: yup.string().required("FA Question is required"),
    faq6: yup.string().required("FA Question is required"),
    answer1: yup.string().required("Answer is required"),
    answer2: yup.string().required("Answer is required"),
    answer3: yup.string().required("Answer is required"),
    answer4: yup.string().required("Answer is required"),
    answer5: yup.string().required("Answer is required"),
    answer6: yup.string().required("Answer is required"),
  });
  useEffect(
    () => {
      const fetchCourseData = async () => {
        const courseId = params.detailId;
        const { data, error } = await supabase
          .from(TableName.UPCOMINGCOURSEDETAILS)
          .select()
          .eq("detailId", courseId);

        if (error) {
          console.error("Error fetching course data:", error.message);
        } else if (data && data.length > 0) {
          const course = data[0];
          formik.setValues({
            courseName: course.courseName,
            courseDesc: course.courseDesc,
            topic1: course.topic1,
            topic2: course.topic2,
            topic3: course.topic3,
            topic4: course.topic4,
            topic5: course.topic5,
            desc1: course.desc1,
            desc2: course.desc2,
            desc3: course.desc3,
            desc4: course.desc4,
            desc5: course.desc5,
            faq1: course.faq1,
            faq2: course.faq2,
            faq3: course.faq3,
            faq4: course.faq4,
            faq5: course.faq5,
            faq6: course.faq6,
            answer1: course.answer1,
            answer2: course.answer2,
            answer3: course.answer3,
            answer4: course.answer4,
            answer5: course.answer5,
            answer6: course.answer6,
          });
          if (course.imageUrl) {
            setImagesPreview([{ imageUrl: course.imageUrl }]);
          }
          setOriginalImage(course.imageUrl);
        }
      };

      if (params.detailId) {
        fetchCourseData();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params.detailId, supabase]
  );
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, event) => {
      if (params.detailId) {
        let publicUrl = originalImage;
        if (isNewImageUp) {
          await removeImage(StorageBucket.UPCOMINGCOURSE, originalImage);
          publicUrl = await uploadImage(
            StorageBucket.UPCOMINGCOURSE,
            imagesPreview[0].imageFile
          );
        }
        const { data, error } = await supabase
          .from(TableName.UPCOMINGCOURSEDETAILS)
          .update([
            {
              courseName: values.courseName,
              courseDesc: values.courseDesc,
              topic1: values.topic1,
              topic2: values.topic2,
              topic3: values.topic3,
              topic4: values.topic4,
              topic5: values.topic5,
              desc1: values.desc1,
              desc2: values.desc2,
              desc3: values.desc3,
              desc4: values.desc4,
              desc5: values.desc5,
              faq1: values.faq1,
              faq2: values.faq2,
              faq3: values.faq3,
              faq4: values.faq4,
              faq5: values.faq5,
              faq6: values.faq6,
              answer1: values.answer1,
              answer2: values.answer2,
              answer3: values.answer3,
              answer4: values.answer4,
              answer5: values.answer5,
              answer6: values.answer6,
              imageUrl: publicUrl,
            },
          ])
          .eq("detailId", detailId);
        if (!error) {
          console.log("course data update", data);
          navigate(`${AppRouts.UPCOMINGCOURSEDETAILS}/${id}`);
          showToast("update", "Course Details");
        }
      } else {
        try {
          let publicUrl = "";
          if (imagesPreview.length > 0) {
            publicUrl = await uploadImage(
              StorageBucket.UPCOMINGCOURSE,
              imagesPreview[0].imageFile
            );
          }
          const { error: insertError } = await supabase
            .from(TableName.UPCOMINGCOURSEDETAILS)
            .insert([
              {
                courseId: id,
                courseName: values.courseName,
                courseDesc: values.courseDesc,
                topic1: values.topic1,
                topic2: values.topic2,
                topic3: values.topic3,
                topic4: values.topic4,
                topic5: values.topic5,
                desc1: values.desc1,
                desc2: values.desc2,
                desc3: values.desc3,
                desc4: values.desc4,
                desc5: values.desc5,
                faq1: values.faq1,
                faq2: values.faq2,
                faq3: values.faq3,
                faq4: values.faq4,
                faq5: values.faq5,
                faq6: values.faq6,
                answer1: values.answer1,
                answer2: values.answer2,
                answer3: values.answer3,
                answer4: values.answer4,
                answer5: values.answer5,
                answer6: values.answer6,
                imageUrl: publicUrl,
              },
            ]);

          if (insertError) {
            console.error("Error inserting data:", insertError.message);
            return;
          }

          navigate(`${AppRouts.UPCOMINGCOURSEDETAILS}/${id}`);
          showToast("success", "Course Details");
        } catch (error) {
          console.error("Error:", error.message);
        }
      }
    },
  });
  const handleImageUpload = async (event) => {
    if (event?.target?.files?.length > 0) {
      const imageUrl = URL.createObjectURL(event?.target?.files[0]);
      setImagesPreview([
        {
          imageFile: event?.target?.files[0],
          imageUrl: imageUrl,
        },
      ]);
      setIsNewImageUp(true);
      setOriginalImage(imageUrl);
    }
  };

  const handleCancelImage = (index) => {
    setImagesPreview([]);
    setOriginalImage(null);
  };
  return (
    <div>
      <SubHeader
        title={
          params.id
            ? "Edit Upcoming Course Details "
            : "Add Upcoming Course Details"
        }
        subtitle="Upcoming Course Details"
        back=" Upcoming Course Details"
        linkProps={{
          to: `${AppRouts.UPCOMINGCOURSEDETAILS}/${id}`,
        }}
      />
      <div style={{ padding: "35px" }}>
        <form onSubmit={formik.handleSubmit}>
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  Course Basic Details
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Please provide Course details
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <div style={{ margin: 17 }}>
                      <ImageUpload
                        isMultiImages={false}
                        handleImageUpload={handleImageUpload}
                        handleCancelImage={handleCancelImage}
                        uploadedImages={imagesPreview}
                        title="Click to upload course Image"
                      />
                    </div>
                  </Grid>
                  <br />
                  <Grid item xs={12}>
                    <InputLabel>
                      <Typography sx={{ color: "black", fontWeight: "500" }}>
                        Course Name
                        <Typography sx={{ color: "red" }} component="span">
                          *
                        </Typography>
                      </Typography>
                    </InputLabel>
                    <CustomTextField
                      name="courseName"
                      value={formik.values.courseName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.courseName &&
                        Boolean(formik.errors.courseName)
                      }
                    />
                    {formik.touched.courseName && formik.errors.courseName && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.courseName}
                      </Typography>
                    )}
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Course Description
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <TextField
                        name="courseDesc"
                        value={formik.values.courseDesc}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.courseDesc &&
                          Boolean(formik.errors.courseDesc)
                        }
                        sx={{ width: "80%" }}
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                      />
                    </Grid>

                    {formik.touched.courseDesc && formik.errors.courseDesc && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                        }}
                      >
                        {formik.errors.courseDesc}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <br />
          <br />
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  Course Topics
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Please provide Course topics
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Topic 1
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <CustomTextField
                        name="topic1"
                        value={formik.values.topic1}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.topic1 && Boolean(formik.errors.topic1)
                        }
                      />
                    </Grid>
                    {formik.touched.topic1 && formik.errors.topic1 && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.topic1}
                      </Typography>
                    )}

                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Topic Desc 1
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <TextField
                        sx={{ width: "80%" }}
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        name="desc1"
                        value={formik.values.desc1}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.desc1 && Boolean(formik.errors.desc1)
                        }
                      />
                      {formik.touched.desc1 && formik.errors.desc1 && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                            //mt: "-15px",
                          }}
                        >
                          {formik.errors.desc1}
                        </Typography>
                      )}
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Topic 2
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <CustomTextField
                        name="topic2"
                        value={formik.values.topic2}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.topic2 && Boolean(formik.errors.topic2)
                        }
                      />
                    </Grid>
                    {formik.touched.topic2 && formik.errors.topic2 && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.topic2}
                      </Typography>
                    )}
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Topic Desc 2
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <TextField
                        sx={{ width: "80%" }}
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        name="desc2"
                        value={formik.values.desc2}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.desc2 && Boolean(formik.errors.desc2)
                        }
                      />
                      {formik.touched.desc2 && formik.errors.desc2 && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                          }}
                        >
                          {formik.errors.desc2}
                        </Typography>
                      )}
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Topic 3
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <CustomTextField
                        name="topic3"
                        value={formik.values.topic3}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.topic3 && Boolean(formik.errors.topic3)
                        }
                      />
                    </Grid>
                    {formik.touched.topic3 && formik.errors.topic3 && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.topic3}
                      </Typography>
                    )}
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Topic Desc 3
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <TextField
                        sx={{ width: "80%" }}
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        name="desc3"
                        value={formik.values.desc3}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.desc3 && Boolean(formik.errors.desc3)
                        }
                      />
                      {formik.touched.desc3 && formik.errors.desc3 && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                          }}
                        >
                          {formik.errors.desc3}
                        </Typography>
                      )}
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Topic 4
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <CustomTextField
                        name="topic4"
                        value={formik.values.topic4}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.topic4 && Boolean(formik.errors.topic4)
                        }
                      />
                    </Grid>
                    {formik.touched.topic4 && formik.errors.topic4 && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.topic4}
                      </Typography>
                    )}
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Topic Desc 4
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <TextField
                        sx={{ width: "80%" }}
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        name="desc4"
                        value={formik.values.desc4}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.desc4 && Boolean(formik.errors.desc4)
                        }
                      />
                      {formik.touched.desc4 && formik.errors.desc4 && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                          }}
                        >
                          {formik.errors.desc4}
                        </Typography>
                      )}
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Topic 5
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <CustomTextField
                        name="topic5"
                        value={formik.values.topic5}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.topic5 && Boolean(formik.errors.topic5)
                        }
                      />
                    </Grid>
                    {formik.touched.topic5 && formik.errors.topic5 && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.topic5}
                      </Typography>
                    )}
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Topic Desc 5
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <TextField
                        sx={{ width: "80%" }}
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        name="desc5"
                        value={formik.values.desc5}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.desc5 && Boolean(formik.errors.desc5)
                        }
                      />
                      {formik.touched.desc5 && formik.errors.desc5 && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                          }}
                        >
                          {formik.errors.desc5}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <br />
          <br />
          <Box
            style={{
              padding: "20px",
              textAlign: "left",
              boxShadow: "0.5px 0.5px 5px 0.5px rgb(223, 218, 218)",
              borderRadius: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  FAQ Section
                  <Typography sx={{ fontSize: "13px", color: "#a3a3b8" }}>
                    Please provide FAQ
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          FAQ 1
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <CustomTextField
                        name="faq1"
                        value={formik.values.faq1}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.faq1 && Boolean(formik.errors.faq1)
                        }
                      />
                    </Grid>
                    {formik.touched.faq1 && formik.errors.faq1 && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.faq1}
                      </Typography>
                    )}

                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Answer 1
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <TextField
                        sx={{ width: "80%" }}
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        name="answer1"
                        value={formik.values.answer1}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.answer1 &&
                          Boolean(formik.errors.answer1)
                        }
                      />
                      {formik.touched.answer1 && formik.errors.answer1 && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                          }}
                        >
                          {formik.errors.answer1}
                        </Typography>
                      )}
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          FAQ 2
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <CustomTextField
                        name="faq2"
                        value={formik.values.faq2}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.faq2 && Boolean(formik.errors.faq2)
                        }
                      />
                    </Grid>
                    {formik.touched.faq2 && formik.errors.faq2 && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.faq2}
                      </Typography>
                    )}
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Answer 2
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <TextField
                        sx={{ width: "80%" }}
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        name="answer2"
                        value={formik.values.answer2}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.answer2 &&
                          Boolean(formik.errors.answer2)
                        }
                      />
                      {formik.touched.answer2 && formik.errors.answer2 && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                          }}
                        >
                          {formik.errors.answer2}
                        </Typography>
                      )}
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          FAQ 3
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <CustomTextField
                        name="faq3"
                        value={formik.values.faq3}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.faq3 && Boolean(formik.errors.faq3)
                        }
                      />
                    </Grid>
                    {formik.touched.faq3 && formik.errors.faq3 && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.faq3}
                      </Typography>
                    )}
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Answer 3
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <TextField
                        sx={{ width: "80%" }}
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        name="answer3"
                        value={formik.values.answer3}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.answer3 &&
                          Boolean(formik.errors.answer3)
                        }
                      />
                      {formik.touched.answer3 && formik.errors.answer3 && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                          }}
                        >
                          {formik.errors.answer3}
                        </Typography>
                      )}
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          FAQ 4
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <CustomTextField
                        name="faq4"
                        value={formik.values.faq4}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.faq4 && Boolean(formik.errors.faq4)
                        }
                      />
                    </Grid>
                    {formik.touched.faq4 && formik.errors.faq4 && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.faq4}
                      </Typography>
                    )}
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Answer 4
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <TextField
                        sx={{ width: "80%" }}
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        name="answer4"
                        value={formik.values.answer4}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.answer4 &&
                          Boolean(formik.errors.answer4)
                        }
                      />
                      {formik.touched.answer4 && formik.errors.answer4 && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                          }}
                        >
                          {formik.errors.answer4}
                        </Typography>
                      )}
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          FAQ 5
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <CustomTextField
                        name="faq5"
                        value={formik.values.faq5}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.faq5 && Boolean(formik.errors.faq5)
                        }
                      />
                    </Grid>
                    {formik.touched.faq5 && formik.errors.faq5 && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.faq5}
                      </Typography>
                    )}
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Answer 5
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <TextField
                        sx={{ width: "80%" }}
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        name="answer5"
                        value={formik.values.answer5}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.answer5 &&
                          Boolean(formik.errors.answer5)
                        }
                      />
                      {formik.touched.answer5 && formik.errors.answer5 && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                          }}
                        >
                          {formik.errors.answer5}
                        </Typography>
                      )}
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          FAQ 6
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <CustomTextField
                        name="faq6"
                        value={formik.values.faq6}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.faq6 && Boolean(formik.errors.faq6)
                        }
                      />
                    </Grid>
                    {formik.touched.faq6 && formik.errors.faq6 && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          mt: "-15px",
                        }}
                      >
                        {formik.errors.faq6}
                      </Typography>
                    )}
                    <Grid item xs={12}>
                      <InputLabel>
                        <Typography sx={{ color: "black", fontWeight: "500" }}>
                          Answer 6
                          <Typography sx={{ color: "red" }} component="span">
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <TextField
                        sx={{ width: "80%" }}
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        name="answer6"
                        value={formik.values.answer6}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.answer6 &&
                          Boolean(formik.errors.answer6)
                        }
                      />
                      {formik.touched.answer6 && formik.errors.answer6 && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                          }}
                        >
                          {formik.errors.answer6}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box pt={3} display="flex" flexDirection="row-reverse">
            <Stack direction="row" spacing={4}>
              <Link to={`${AppRouts.UPCOMINGCOURSEDETAILS}/${id}`}>
                <Button type="cancle" variant="contained" color="primary">
                  Cancel
                </Button>
              </Link>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Stack>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default AddUpcomingCourseDetails;
