import React, { useState } from "react";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import { NavLink, useNavigate } from "react-router-dom";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import ViewCarouselOutlinedIcon from "@mui/icons-material/ViewCarouselOutlined";
import HomeRepairServiceOutlinedIcon from "@mui/icons-material/HomeRepairServiceOutlined";
import { AppRouts } from "./Constant";
import { AppBar, Button, Divider, Toolbar, Typography } from "@mui/material";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import SubscriptionsOutlinedIcon from "@mui/icons-material/SubscriptionsOutlined";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import useSupabase from "./utils/useSupabase";
import BookOutlinedIcon from "@mui/icons-material/BookOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined";
import OndemandVideoOutlinedIcon from "@mui/icons-material/OndemandVideoOutlined";
import UpcomingOutlinedIcon from "@mui/icons-material/UpcomingOutlined";

const Sidebar = ({ children, setAuth }) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);
  const supabase = useSupabase();
  const navigate = useNavigate();
  const menuItem = [
    {
      path: AppRouts.DASHBOARD,
      name: "Dashboard",
      icon: <DashboardCustomizeOutlinedIcon />,
    },
    {
      path: AppRouts.CATEGORY,
      name: "Category",
      icon: <CategoryOutlinedIcon />,
    },
    {
      path: AppRouts.SUBCATEGORY,
      name: "Subcategory",
      icon: <GridViewOutlinedIcon />,
    },
    {
      path: AppRouts.COURSE,
      name: "Course",
      icon: <LibraryBooksOutlinedIcon />,
    },
    {
      path: AppRouts.WORKSHOP,
      name: "Workshop",
      icon: <HomeRepairServiceOutlinedIcon />,
    },
    {
      path: AppRouts.UPCOMINGCOURSE,
      name: "Upcoming Course",
      icon: <UpcomingOutlinedIcon />,
    },
    {
      path: AppRouts.BANNER,
      name: "Banner",
      icon: <ViewCarouselOutlinedIcon />,
    },

    {
      path: AppRouts.WEBSITEREVIEW,
      name: "Review",
      icon: <RateReviewOutlinedIcon />,
    },
    {
      path: AppRouts.TESTIMONIALS,
      name: "Testimonials",
      icon: <OndemandVideoOutlinedIcon />,
    },
    {
      path: AppRouts.ENROLL,
      name: "Enroll Courses",
      icon: <SubscriptionsOutlinedIcon />,
    },
    {
      path: AppRouts.EnrollWorkshop,
      name: "Enroll Workshop",
      icon: <AddBoxOutlinedIcon />,
    },
    {
      path: AppRouts.BLOG,
      name: "Blogs",
      icon: <BookOutlinedIcon />,
    },
    {
      path: AppRouts.PAYMENT,
      name: "Payment",
      icon: <PaymentOutlinedIcon />,
    },
    {
      path: AppRouts.TRAINING,
      name: "Certification Training",
      icon: <WorkspacePremiumOutlinedIcon />,
    },
    {
      path: AppRouts.PLACEMENT,
      name: "Placement Companies",
      icon: <BusinessOutlinedIcon />,
    },
  ];
  return (
    <div className="container">
      <div
        style={{ width: isOpen ? "260px" : "60px", position: "fixed" }}
        className="sidebar"
      >
        <div className="top_section">
          <h1 style={{ display: isOpen ? "block" : "none" }} className="logo">
            EduCampus
          </h1>
          <div style={{ marginLeft: isOpen ? "80px" : "0px" }} className="bars">
            {isOpen ? (
              <ArrowBackIosNewOutlinedIcon
                sx={{ cursor: "pointer" }}
                onClick={toggle}
              />
            ) : (
              <ArrowForwardIosOutlinedIcon
                sx={{ cursor: "pointer" }}
                onClick={toggle}
              />
            )}
          </div>
        </div>
        <Divider />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            overflow: "scroll",
          }}
        >
          {menuItem.map((item, index) => (
            <NavLink
              to={item.path}
              key={index}
              className="link"
              activeClassName="active"
            >
              <div className="icon">{item.icon}</div>
              <div
                style={{ display: isOpen ? "block" : "none" }}
                className="link_text"
              >
                {item.name}
              </div>
            </NavLink>
          ))}
          <Button
            variant="outlined"
            color="error"
            onClick={async () => {
              let { data, error } = await supabase.auth.signOut();
              navigate("/");
              setAuth(false);
            }}
          >
            LogOut
          </Button>
        </div>
      </div>
      <main
        style={{
          marginLeft: isOpen ? "260px" : "50px",
          transition: "margin-left 0.3s ease-in-out",
        }}
      >
        {children}
      </main>

      <AppBar
        sx={{
          backgroundColor: "white",
          left: isOpen ? "260px" : "60px",
          width: isOpen ? "calc(100% - 260px)" : "calc(100% - 50px)",
        }}
        position="fixed"
      >
        <Toolbar>
          <Typography sx={{ color: "green" }} variant="h6">
            Welcome to EduCampus Technologies Admin Portal 😄
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Sidebar;
