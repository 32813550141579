import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Sidebar from "./SideBar.js";
import Dashboard from "./Dashboard.js";
import { AppRouts } from "./Constant.js";
import Category from "./Category.js";
import AddCategory from "./AddCategory.js";
import Subcategory from "./Subcategory.js";
import AddSubcategory from "./AddSubcategory.js";
import Course from "./Course.js";
import AddCourse from "./AddCourse.js";
import Banner from "./Banner.js";
import Workshop from "./Workshop.js";
import AddWorkshop from "./AddWorkshop.js";
import CourseDetails from "./CourseDetails.js";
import AddCourseDetails from "./AddCourseDetails.js";
import WorkshopDetails from "./WorkshopDetails.js";
import AddWorkshopDetails from "./AddWorkshopDetails.js";
import WebsiteReview from "./WebsiteReview.js";
import AddWebsiteReview from "./AddWebsiteReview.js";
import EnrollCourses from "./EnrollCourses.js";
import EnrollWorkshop from "./EnrollWorkshop.js";
import SignIn from "./SignIn.js";
import useSupabase from "./utils/useSupabase.jsx";
import Blog from "./Blog.js";
import AddBlog from "./AddBlog.js";
import Payment from "./Payment.js";
import Training from "./CertificationTraining.js";
import Placement from "./PlacementCompanies.js";
import AddTraining from "./AddCertificationTraining.js";
import AddPlacement from "./AddPlacementCompanies.js";
import Testimonials from "./Testimonials.js";
import AddTestimonials from "./AddTestimonials.js";
import UpcomingCourse from "./UpcomingCourse.js";
import AddUpcomingCourse from "./AddUpcomingCourse.js";
import UpcomingCourseDetails from "./UpcomingCourseDetails.js";
import AddUpcomingCourseDetails from "./AddUpcomingCourseDetails.js";

const App = () => {
  const supabase = useSupabase();
  const [Auth, setAuth] = useState(false);
  const get = async () => {
    const data = await supabase.auth.getSession();
    if (data.data.session !== null) {
      setAuth(true);
    }
  };
  useEffect(
    () => {
      get();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <BrowserRouter>
      {!Auth ? (
        <Routes>
          <Route
            path="/"
            element={<SignIn Auth={Auth} setAuth={setAuth} get={get} />}
          />
          <Route path="*" element={<SignIn />} />
        </Routes>
      ) : (
        <Sidebar setAuth={setAuth}>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path={AppRouts.DASHBOARD} element={<Dashboard />} />
            <Route path={AppRouts.CATEGORY} element={<Category />} />
            <Route
              path={AppRouts.ADDCATEGORY_WITHOUT_ID}
              element={<AddCategory />}
            />
            <Route
              path={AppRouts.ADDSUBCATEGORY_WITHOUT_ID}
              element={<AddSubcategory />}
            />
            <Route path={AppRouts.ADDCATEGORY} element={<AddCategory />} />
            <Route
              path={AppRouts.ADDSUBCATEGORY}
              element={<AddSubcategory />}
            />
            <Route path={AppRouts.SUBCATEGORY} element={<Subcategory />} />
            <Route path={AppRouts.COURSE} element={<Course />} />
            <Route path={AppRouts.ADDCOURSE} element={<AddCourse />} />
            <Route
              path={AppRouts.ADDCOURSE_WITHOUT_ID}
              element={<AddCourse />}
            />
            <Route path={AppRouts.WORKSHOP} element={<Workshop />} />
            <Route
              path={AppRouts.ADDWORKSHOP_WITHOUT_ID}
              element={<AddWorkshop />}
            />
            <Route path={AppRouts.ADDWORKSHOP} element={<AddWorkshop />} />
            <Route
              path={`${AppRouts.WORKSHOPDETAILS}/:id`}
              element={<WorkshopDetails />}
            />
            <Route
              path={`${AppRouts.ADDWORKSHOPDETAILS_WITHOUT_ID}/:id`}
              element={<AddWorkshopDetails />}
            />
            <Route
              path={`${AppRouts.ADDWORKSHOPDETAILS}/:id/:detailId`}
              element={<AddWorkshopDetails />}
            />
            <Route path={AppRouts.BANNER} element={<Banner />} />
            <Route
              path={`${AppRouts.COURSEDETAILS}/:id`}
              element={<CourseDetails />}
            />
            <Route
              path={`${AppRouts.ADDCOURSEDETAILS_WITHOUT_ID}/:id`}
              element={<AddCourseDetails />}
            />
            <Route
              path={`${AppRouts.ADDCOURSEDETAILS}/:id/:detailId`}
              element={<AddCourseDetails />}
            />
            <Route path={AppRouts.WEBSITEREVIEW} element={<WebsiteReview />} />
            <Route
              path={AppRouts.ADDWEBSITEREVIEW_WITHOUT_ID}
              element={<AddWebsiteReview />}
            />
            <Route
              path={AppRouts.ADDWEBSITEREVIEW}
              element={<AddWebsiteReview />}
            />
            <Route path={AppRouts.ENROLL} element={<EnrollCourses />} />
            <Route
              path={AppRouts.EnrollWorkshop}
              element={<EnrollWorkshop />}
            />
            <Route path={AppRouts.BLOG} element={<Blog />} />
            <Route path={AppRouts.ADDBLOG_WITHOUT_ID} element={<AddBlog />} />
            <Route path={AppRouts.ADDBLOG} element={<AddBlog />} />
            <Route path={AppRouts.PAYMENT} element={<Payment />} />
            <Route path={AppRouts.TRAINING} element={<Training />} />
            <Route path={AppRouts.PLACEMENT} element={<Placement />} />
            <Route path={AppRouts.ADDTRAINING} element={<AddTraining />} />
            <Route path={AppRouts.ADDPLACEMENT} element={<AddPlacement />} />
            <Route
              path={AppRouts.ADDTRAINING_WITHOUT_ID}
              element={<AddTraining />}
            />
            <Route
              path={AppRouts.ADDPLACEMENT_WITHOUT_ID}
              element={<AddPlacement />}
            />
            <Route path={AppRouts.TESTIMONIALS} element={<Testimonials />} />
            <Route
              path={AppRouts.ADDTESTIMONIALS_WITHOUT_ID}
              element={<AddTestimonials />}
            />
            <Route
              path={AppRouts.ADDTESTIMONIALS}
              element={<AddTestimonials />}
            />
            <Route
              path={AppRouts.UPCOMINGCOURSE}
              element={<UpcomingCourse />}
            />
            <Route
              path={AppRouts.ADDUPCOMINGCOURSE_WITHOUT_ID}
              element={<AddUpcomingCourse />}
            />
            <Route
              path={AppRouts.ADDUPCOMINGCOURSE}
              element={<AddUpcomingCourse />}
            />
            <Route
              path={`${AppRouts.UPCOMINGCOURSEDETAILS}/:id`}
              element={<UpcomingCourseDetails />}
            />
            <Route
              path={`${AppRouts.ADDUPCOMINGCOURSEDETAILS_WITHOUT_ID}/:id`}
              element={<AddUpcomingCourseDetails />}
            />
            <Route
              path={`${AppRouts.ADDUPCOMINGCOURSEDETAILS}/:id/:detailId`}
              element={<AddUpcomingCourseDetails />}
            />
          </Routes>
        </Sidebar>
      )}
    </BrowserRouter>
  );
};

export default App;
